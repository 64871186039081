<template>
    <div class="auth">
     <img class="cover" src="../../assets/img/auth/Cover.svg" alt="">
     <div class="container">
         <CompanyLogin />
     </div>
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'Login Company',
 components: {
    CompanyLogin: defineAsyncComponent( () => import('@/components/auth/CompanyLogin.vue') ),
 },
 }
 </script>
 
 